<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Liste des Utilisateurs"
      class="px-5 py-3"
    >
      <v-row
        class="align-center"
      >
        <v-col
          cols="2"
          md="3"
          lg="3"
        >
          <v-text-field
            v-model="rechercheNom"
            label="Rechercher"
            color="success"
            hide-details
            style=""
          >
            <template
              v-if="$vuetify.breakpoint.mdAndUp"
              v-slot:append-outer
            >
              <v-btn
                elevation=""
                small
                @click="rechercherNom(rechercheNom)"
              >
                <v-icon
                  background-color="success"
                >
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="selectStatus"
            :items="statutClient"
            item-text="label"
            item-value="id"
            label="Statut"
            @change="onChange()"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-btn
            elevation=""
            small
            @click="resetFiltres(rechercheNom)"
          >
            <v-icon
              background-color="success"
              class="mr-2"
            >
              mdi-reload
            </v-icon>
            Reset
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <!-- <v-btn
            disabled
            color="success"
            @click="trierDate()"
          >
            Trier par date
          </v-btn> -->
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <!-- <v-btn
          color="success"
          >
            <download-excel
              :data="users"
              :name="fileNameExport"
            >
            <v-icon
            class="mr-2"
            >mdi mdi-folder-download</v-icon>
              Exporter les utilisateurs
            </download-excel>
          </v-btn> -->
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-right"
        >
          <div
            v-if="recherche===true"
            class="my-2"
          >
            <v-btn
              icon
              small
              @click="supprimerNom()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              Id
            </th>
            <th class="primary--text">
              Nom
            </th>
            <th class="primary--text">
              Prenom
            </th>
            <th class="primary--text">
              Mail
            </th>
            <th class="primary--text">
              Nb Commande
            </th>
            <th class="primary--text">
              Statut
            </th>
            <th class="primary--text" />
          </tr>
        </thead>

        <tbody v-if="recherche===false">
          <tr
            v-for="user in pageOfItems"
            :key="user.id"
          >
            <td> {{ user.id }} </td>
            <td> {{ user.nom }} </td>
            <td> {{ user.prenom }} </td>
            <td> {{ user.mail }} </td>
            <td> 0 </td>
            <td> {{ user.labelStatusClient }} </td>
            <td>
              <v-btn
                small
                color="edit"
                @click="GoDetailsUser(user)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="element in test"
            :key="element.id"
          >
            <td>
              <v-btn
                @click="GoDetailsUser(user)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </td>
            <td> {{ element.id }} </td>
            <td> {{ element.nom }} </td>
            <td> {{ element.prenom }} </td>
            <td> {{ element.mail }} </td>
            <td> {{ element.labelStatusClient }} </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <jw-pagination
      :items="users"
      @changePage="onChangePage"
    />
    <div class="py-3" />
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import JsonExcel from 'vue-json-excel'

  Vue.use(VueAxios, axios)
  Vue.component('downloadExcel', JsonExcel)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      websiteid: '',
      pageOfItems: [],
      users: [],
      fileNameExport: 'filename.xls',
      rechercheNom: null,
      recherche: false,
      selectStatus: '',
      test: [],
      statutClient: [
        {
          id: 1,
          label: 'Tous',
        },
        {
          id: 2,
          label: 'Pro',
        },
        {
          id: 3,
          label: 'Particulier',
        },
        {
          id: 4,
          label: 'Attente Statut Pro',
        },
      ],
    }),
    computed: {
    },
    mounted () {
      this.websiteid = this.$cookie.get('website_id')
      // Récupération des commandes
      Vue.axios.get('https://api.espace-jantes.com/getUsers', { params: { websiteid: this.websiteid } }).then((response) => {
        response.data.forEach(element => {
          element.labelStatusClient = this.GetTypeClient(element.status)
          this.users.push(element)
        })
      })
    },
    methods: {
      onChangePage (pageOfItems) {
        // update page of items
        this.pageOfItems = pageOfItems
      },
      resetFiltres () {
        this.$router.go(0)
      },
      GetTypeClient: function (idTypeClient) {
        var elementToReturn
        switch (idTypeClient) {
          case 1:
            elementToReturn = 'Admin'
            break
          case 2:
            elementToReturn = 'Pro'
            break
          case 3:
            elementToReturn = 'Particulier'
            break
          case 4:
            elementToReturn = 'Attente Statut Pro'
            break
          default:
            elementToReturn = 'Particulier'
        }
        return elementToReturn
      },
      GoDetailsUser: function (user) {
        this.$router.push({ name: 'UsersDetails', params: { infoUser: user } })
      },
      rechercherNom: function (rechercheNom) {
        if (rechercheNom === '') {
          this.$router.go(0)
        }
        if (this.recherche === false) {
          this.recherche = true
          this.users.forEach(user => {
            if (user.nom === rechercheNom) {
              this.test.push(user)
            }
          })
        } else {
          this.test = []
          this.users.forEach(user => {
            if (user.nom === rechercheNom) {
              this.test.push(user)
            }
          })
        }
      },
      supprimerNom: function (rechercheNom) {
        this.recherche = false
        this.test = []
      },
      onChange: function () {
        this.users = []
        Vue.axios.get('https://api.espace-jantes.com/getUsersByStatut', {
          params: { status: this.selectStatus, websiteid: this.websiteid },
        }).then((response) => {
          response.data.forEach(element => {
            element.labelStatusClient = this.GetTypeClient(element.status)
            this.users.push(element)
          })
        })
      },
    },
  }
</script>
<style scoped>
  input[type=text] {
    border: 2px solid black !important;
    border-radius : 4px;
    margin-right : 15px;
  }
  th {
    font-size: 14px !important;
  }
</style>
